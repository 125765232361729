// Generated by Framer (cffb68d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Yg8JYD3Nu", "M5yvo0Oy7", "zDUTO7ydX"];

const serializationHash = "framer-hS6Q6"

const variantClassNames = {M5yvo0Oy7: "framer-v-1hv2whl", Yg8JYD3Nu: "framer-v-z716pa", zDUTO7ydX: "framer-v-1s232w3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const transition2 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Hover: "zDUTO7ydX", Normal: "M5yvo0Oy7", Small: "Yg8JYD3Nu"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Yg8JYD3Nu"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Yg8JYD3Nu", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({zDUTO7ydX: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-z716pa", className, classNames)} data-framer-name={"Small"} layoutDependency={layoutDependency} layoutId={"Yg8JYD3Nu"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({M5yvo0Oy7: {"data-framer-name": "Normal"}, zDUTO7ydX: {"data-framer-name": "Hover"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1jtyu5r"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"Vqmo4P_0K"} style={{backgroundColor: "rgb(1, 113, 227)", borderBottomLeftRadius: 174, borderBottomRightRadius: 174, borderTopLeftRadius: 174, borderTopRightRadius: 174}} variants={{M5yvo0Oy7: {backgroundColor: "var(--token-64227952-a190-490e-97d6-1283a1507fc8, rgb(30, 177, 255))"}, zDUTO7ydX: {backgroundColor: "var(--token-64227952-a190-490e-97d6-1283a1507fc8, rgb(30, 177, 255))"}}}><motion.div className={"framer-1ex2nrx"} data-framer-name={"Pl"} layoutDependency={layoutDependency} layoutId={"CUo24wiss"} style={{backgroundColor: "rgb(211, 225, 250)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100}} variants={{zDUTO7ydX: {backgroundColor: "rgb(255, 255, 255)"}}}/><motion.div className={"framer-1maqoxx"} data-framer-name={"us"} layoutDependency={layoutDependency} layoutId={"Z2nMtipzS"} style={{backgroundColor: "rgb(211, 225, 250)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, rotate: 90}} variants={{zDUTO7ydX: {backgroundColor: "rgb(255, 255, 255)"}}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hS6Q6.framer-1cyz2gi, .framer-hS6Q6 .framer-1cyz2gi { display: block; }", ".framer-hS6Q6.framer-z716pa { height: 36px; overflow: hidden; position: relative; width: 36px; }", ".framer-hS6Q6 .framer-1jtyu5r { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 1px; justify-content: center; left: calc(50.00000000000002% - 1px / 2); overflow: visible; padding: 0px; position: absolute; top: calc(50.00000000000002% - 1px / 2); width: 1px; }", ".framer-hS6Q6 .framer-1ex2nrx, .framer-hS6Q6 .framer-1maqoxx { bottom: 0px; flex: none; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hS6Q6 .framer-1jtyu5r { gap: 0px; } .framer-hS6Q6 .framer-1jtyu5r > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-hS6Q6 .framer-1jtyu5r > :first-child { margin-left: 0px; } .framer-hS6Q6 .framer-1jtyu5r > :last-child { margin-right: 0px; } }", ".framer-hS6Q6.framer-v-1hv2whl .framer-1jtyu5r, .framer-hS6Q6.framer-v-1s232w3 .framer-1jtyu5r { bottom: 0px; height: unset; left: 0px; right: 0px; top: 0px; width: unset; }", ".framer-hS6Q6.framer-v-1hv2whl .framer-1ex2nrx, .framer-hS6Q6.framer-v-1hv2whl .framer-1maqoxx, .framer-hS6Q6.framer-v-1s232w3 .framer-1ex2nrx, .framer-hS6Q6.framer-v-1s232w3 .framer-1maqoxx { bottom: unset; height: 16px; left: calc(50.00000000000002% - 3px / 2); right: unset; top: calc(50.00000000000002% - 16px / 2); width: 3px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 36
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"M5yvo0Oy7":{"layout":["fixed","fixed"]},"zDUTO7ydX":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerXJPTV3hPy: React.ComponentType<Props> = withCSS(Component, css, "framer-hS6Q6") as typeof Component;
export default FramerXJPTV3hPy;

FramerXJPTV3hPy.displayName = "Apple Read More/Plus Icon";

FramerXJPTV3hPy.defaultProps = {height: 36, width: 36};

addPropertyControls(FramerXJPTV3hPy, {variant: {options: ["Yg8JYD3Nu", "M5yvo0Oy7", "zDUTO7ydX"], optionTitles: ["Small", "Normal", "Hover"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerXJPTV3hPy, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})